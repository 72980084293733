import React from 'react';

const NavContent = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8637 28.432H6C5.73811 28.4341 5.47811 28.3845 5.23555 28.2857C4.99299 28.187 4.77265 28.0411 4.58672 27.8566C4.40079 27.6722 4.25305 27.4529 4.15234 27.2111C4.05164 26.9694 3.99999 26.7099 4 26.448V6C4 5.46957 4.21047 4.96101 4.58555 4.58594C4.96062 4.21086 5.46957 4 6 4H16.8637C17.3941 4 17.9031 4.21086 18.2781 4.58594C18.6532 4.96101 18.8637 5.46957 18.8637 6V26.448C18.8637 26.7099 18.812 26.9694 18.7113 27.2111C18.6106 27.4529 18.4629 27.6722 18.277 27.8566C18.091 28.0411 17.8707 28.187 17.6281 28.2857C17.3856 28.3845 17.1256 28.4341 16.8637 28.432V28.432ZM6 4.8C5.68174 4.8 5.37622 4.92652 5.15117 5.15156C4.92613 5.37661 4.8 5.68174 4.8 6V26.448C4.79999 26.6049 4.83091 26.7602 4.89141 26.9049C4.95191 27.0496 5.0407 27.1809 5.15234 27.291C5.26399 27.4012 5.3963 27.4881 5.5418 27.5467C5.6873 27.6052 5.84317 27.6341 6 27.632H16.8637C17.1792 27.6321 17.4821 27.5079 17.7066 27.2863C17.9312 27.0647 18.0595 26.7635 18.0637 26.448V6C18.0595 5.68303 17.932 5.38021 17.7078 5.15605C17.4837 4.9319 17.1806 4.80414 16.8637 4.8H6Z"
      fill="#1A1A1A"
    />
    <mask id="path-2-inside-1_3560_251813" fill="white">
      <path d="M18.4637 6.95625H4.4C4.29391 6.95625 4.1922 6.91408 4.11719 6.83906C4.04217 6.76405 4 6.66234 4 6.55625C4 6.45016 4.04217 6.34826 4.11719 6.27324C4.1922 6.19823 4.29391 6.15625 4.4 6.15625H18.4637C18.5698 6.15625 18.6719 6.19823 18.7469 6.27324C18.8219 6.34826 18.8637 6.45016 18.8637 6.55625C18.8637 6.66234 18.8219 6.76405 18.7469 6.83906C18.6719 6.91408 18.5698 6.95625 18.4637 6.95625Z" />
    </mask>
    <path
      d="M18.4637 6.95625H4.4C4.29391 6.95625 4.1922 6.91408 4.11719 6.83906C4.04217 6.76405 4 6.66234 4 6.55625C4 6.45016 4.04217 6.34826 4.11719 6.27324C4.1922 6.19823 4.29391 6.15625 4.4 6.15625H18.4637C18.5698 6.15625 18.6719 6.19823 18.7469 6.27324C18.8219 6.34826 18.8637 6.45016 18.8637 6.55625C18.8637 6.66234 18.8219 6.76405 18.7469 6.83906C18.6719 6.91408 18.5698 6.95625 18.4637 6.95625Z"
      fill="#1A1A1A"
    />
    <path
      d="M18.4637 5.95625H4.4V7.95625H18.4637V5.95625ZM4.4 5.95625C4.5594 5.95625 4.71197 6.01963 4.82429 6.13196L3.41008 7.54617C3.67244 7.80853 4.02842 7.95625 4.4 7.95625V5.95625ZM4.82429 6.13196C4.93662 6.24428 5 6.39685 5 6.55625H3C3 6.92783 3.14772 7.28381 3.41008 7.54617L4.82429 6.13196ZM5 6.55625C5 6.71514 4.93699 6.86765 4.82429 6.98035L3.41008 5.56614C3.14736 5.82886 3 6.18519 3 6.55625H5ZM4.82429 6.98035C4.71108 7.09356 4.55815 7.15625 4.4 7.15625V5.15625C4.02968 5.15625 3.67332 5.30289 3.41008 5.56614L4.82429 6.98035ZM4.4 7.15625H18.4637V5.15625H4.4V7.15625ZM18.4637 7.15625C18.3066 7.15625 18.1537 7.09429 18.0398 6.98035L19.454 5.56614C19.19 5.30216 18.833 5.15625 18.4637 5.15625V7.15625ZM18.0398 6.98035C17.9253 6.86589 17.8637 6.71263 17.8637 6.55625H19.8637C19.8637 6.1877 19.7185 5.83062 19.454 5.56614L18.0398 6.98035ZM17.8637 6.55625C17.8637 6.39935 17.9257 6.24605 18.0398 6.13196L19.454 7.54617C19.7181 7.28204 19.8637 6.92532 19.8637 6.55625H17.8637ZM18.0398 6.13196C18.1528 6.0189 18.3053 5.95625 18.4637 5.95625V7.95625C18.8342 7.95625 19.1909 7.80926 19.454 7.54617L18.0398 6.13196Z"
      fill="#344054"
      mask="url(#path-2-inside-1_3560_251813)"
    />
    <mask id="path-4-inside-2_3560_251813" fill="white">
      <path d="M18.4637 25.3439H4.4C4.29391 25.3439 4.1922 25.3018 4.11719 25.2268C4.04217 25.1517 4 25.05 4 24.9439C4 24.8379 4.04217 24.7361 4.11719 24.6611C4.1922 24.5861 4.29391 24.5439 4.4 24.5439H18.4637C18.5698 24.5439 18.6719 24.5861 18.7469 24.6611C18.8219 24.7361 18.8637 24.8379 18.8637 24.9439C18.8637 25.05 18.8219 25.1517 18.7469 25.2268C18.6719 25.3018 18.5698 25.3439 18.4637 25.3439Z" />
    </mask>
    <path
      d="M18.4637 25.3439H4.4C4.29391 25.3439 4.1922 25.3018 4.11719 25.2268C4.04217 25.1517 4 25.05 4 24.9439C4 24.8379 4.04217 24.7361 4.11719 24.6611C4.1922 24.5861 4.29391 24.5439 4.4 24.5439H18.4637C18.5698 24.5439 18.6719 24.5861 18.7469 24.6611C18.8219 24.7361 18.8637 24.8379 18.8637 24.9439C18.8637 25.05 18.8219 25.1517 18.7469 25.2268C18.6719 25.3018 18.5698 25.3439 18.4637 25.3439Z"
      fill="#1A1A1A"
    />
    <path
      d="M18.4637 24.3439H4.4V26.3439H18.4637V24.3439ZM4.4 24.3439C4.5594 24.3439 4.71197 24.4073 4.82429 24.5197L3.41008 25.9339C3.67244 26.1962 4.02842 26.3439 4.4 26.3439V24.3439ZM4.82429 24.5197C4.93662 24.632 5 24.7845 5 24.9439H3C3 25.3155 3.14772 25.6715 3.41008 25.9339L4.82429 24.5197ZM5 24.9439C5 25.1033 4.93662 25.2559 4.82429 25.3682L3.41008 23.954C3.14772 24.2164 3 24.5724 3 24.9439H5ZM4.82429 25.3682C4.71197 25.4806 4.5594 25.5439 4.4 25.5439V23.5439C4.02842 23.5439 3.67244 23.6917 3.41008 23.954L4.82429 25.3682ZM4.4 25.5439H18.4637V23.5439H4.4V25.5439ZM18.4637 25.5439C18.3053 25.5439 18.1528 25.4813 18.0398 25.3682L19.454 23.954C19.1909 23.6909 18.8342 23.5439 18.4637 23.5439V25.5439ZM18.0398 25.3682C17.9257 25.2541 17.8637 25.1008 17.8637 24.9439H19.8637C19.8637 24.5749 19.7181 24.2182 19.454 23.954L18.0398 25.3682ZM17.8637 24.9439C17.8637 24.787 17.9257 24.6337 18.0398 24.5197L19.454 25.9339C19.7181 25.6697 19.8637 25.313 19.8637 24.9439H17.8637ZM18.0398 24.5197C18.1528 24.4066 18.3053 24.3439 18.4637 24.3439V26.3439C18.8342 26.3439 19.1909 26.197 19.454 25.9339L18.0398 24.5197Z"
      fill="#344054"
      mask="url(#path-4-inside-2_3560_251813)"
    />
    <mask id="path-6-inside-3_3560_251813" fill="white">
      <path d="M12.1605 26.9123H10.7047C10.5986 26.9123 10.4969 26.8701 10.4219 26.7951C10.3469 26.7201 10.3047 26.6184 10.3047 26.5123C10.3047 26.4062 10.3469 26.3045 10.4219 26.2295C10.4969 26.1545 10.5986 26.1123 10.7047 26.1123H12.1605C12.2666 26.1123 12.3683 26.1545 12.4434 26.2295C12.5184 26.3045 12.5605 26.4062 12.5605 26.5123C12.5605 26.6184 12.5184 26.7201 12.4434 26.7951C12.3683 26.8701 12.2666 26.9123 12.1605 26.9123Z" />
    </mask>
    <path
      d="M12.1605 26.9123H10.7047C10.5986 26.9123 10.4969 26.8701 10.4219 26.7951C10.3469 26.7201 10.3047 26.6184 10.3047 26.5123C10.3047 26.4062 10.3469 26.3045 10.4219 26.2295C10.4969 26.1545 10.5986 26.1123 10.7047 26.1123H12.1605C12.2666 26.1123 12.3683 26.1545 12.4434 26.2295C12.5184 26.3045 12.5605 26.4062 12.5605 26.5123C12.5605 26.6184 12.5184 26.7201 12.4434 26.7951C12.3683 26.8701 12.2666 26.9123 12.1605 26.9123Z"
      fill="#1A1A1A"
    />
    <path
      d="M12.1605 25.9123H10.7047V27.9123H12.1605V25.9123ZM10.7047 25.9123C10.8641 25.9123 11.0167 25.9757 11.129 26.088L9.71477 27.5022C9.97712 27.7646 10.3331 27.9123 10.7047 27.9123V25.9123ZM11.129 26.088C11.2413 26.2003 11.3047 26.3529 11.3047 26.5123H9.30469C9.30469 26.8839 9.45241 27.2399 9.71477 27.5022L11.129 26.088ZM11.3047 26.5123C11.3047 26.6717 11.2413 26.8243 11.129 26.9366L9.71477 25.5224C9.45241 25.7847 9.30469 26.1407 9.30469 26.5123H11.3047ZM11.129 26.9366C11.0167 27.0489 10.8641 27.1123 10.7047 27.1123V25.1123C10.3331 25.1123 9.97712 25.26 9.71477 25.5224L11.129 26.9366ZM10.7047 27.1123H12.1605V25.1123H10.7047V27.1123ZM12.1605 27.1123C12.0011 27.1123 11.8486 27.0489 11.7363 26.9366L13.1505 25.5224C12.8881 25.26 12.5321 25.1123 12.1605 25.1123V27.1123ZM11.7363 26.9366C11.6239 26.8243 11.5605 26.6717 11.5605 26.5123H13.5605C13.5605 26.1407 13.4128 25.7847 13.1505 25.5224L11.7363 26.9366ZM11.5605 26.5123C11.5605 26.3529 11.6239 26.2003 11.7363 26.088L13.1505 27.5022C13.4128 27.2399 13.5605 26.8839 13.5605 26.5123H11.5605ZM11.7363 26.088C11.8486 25.9757 12.0011 25.9123 12.1605 25.9123V27.9123C12.5321 27.9123 12.8881 27.7646 13.1505 27.5022L11.7363 26.088Z"
      fill="#344054"
      mask="url(#path-6-inside-3_3560_251813)"
    />
    <path
      d="M7.19967 22.1418C6.89062 22.1259 6.59274 22.0208 6.34224 21.8391C6.09175 21.6574 5.89939 21.4068 5.78834 21.118C5.67729 20.8291 5.6522 20.5143 5.71646 20.2115C5.78073 19.9088 5.93124 19.6313 6.15006 19.4125C6.36888 19.1937 6.64656 19.043 6.94928 18.9787C7.25199 18.9145 7.56707 18.9394 7.85592 19.0504C8.14476 19.1615 8.39492 19.3538 8.57662 19.6043C8.75832 19.8548 8.86347 20.1527 8.87935 20.4617C8.89085 20.6854 8.85542 20.909 8.77506 21.118C8.69469 21.327 8.5713 21.5169 8.41295 21.6752C8.25459 21.8336 8.06495 21.957 7.85592 22.0373C7.64688 22.1177 7.42332 22.1533 7.19967 22.1418V22.1418ZM7.19967 19.5819C7.02562 19.5819 6.85532 19.6334 6.7106 19.7301C6.56589 19.8268 6.45299 19.9642 6.38639 20.125C6.31978 20.2858 6.30243 20.4627 6.33639 20.6334C6.37034 20.8041 6.45433 20.9609 6.5774 21.084C6.70047 21.2071 6.85709 21.2909 7.02779 21.3248C7.19849 21.3588 7.37559 21.3414 7.53638 21.2748C7.69718 21.2082 7.83461 21.0953 7.93131 20.9506C8.028 20.8059 8.07935 20.6358 8.07935 20.4617C8.07935 20.3462 8.05678 20.2318 8.01256 20.125C7.96833 20.0183 7.90365 19.9212 7.82193 19.8395C7.74022 19.7578 7.64315 19.6929 7.53638 19.6487C7.42962 19.6044 7.31523 19.5819 7.19967 19.5819Z"
      fill="#344054"
    />
    <path
      d="M13.6645 12.6853C13.352 12.6697 13.0509 12.5628 12.7985 12.3777C12.5461 12.1927 12.3535 11.9377 12.2446 11.6443C12.1357 11.3509 12.1151 11.0318 12.1856 10.7269C12.2561 10.422 12.4145 10.1445 12.6411 9.92868C12.8677 9.71285 13.1526 9.56819 13.4606 9.51266C13.7686 9.45714 14.0862 9.49328 14.3739 9.61638C14.6616 9.73948 14.9072 9.94413 15.0798 10.2052C15.2523 10.4663 15.3442 10.7724 15.3446 11.0853C15.3449 11.3023 15.3011 11.5172 15.2157 11.7168C15.1303 11.9163 15.0053 12.0962 14.8481 12.2459C14.691 12.3955 14.5051 12.5117 14.3016 12.5873C14.0982 12.6628 13.8813 12.6962 13.6645 12.6853ZM13.6645 10.1254C13.549 10.1254 13.4346 10.1481 13.3278 10.1924C13.221 10.2366 13.124 10.3013 13.0423 10.383C12.9605 10.4647 12.8959 10.5618 12.8516 10.6685C12.8074 10.7753 12.7848 10.8897 12.7848 11.0052C12.7848 11.2386 12.8772 11.4625 13.0423 11.6275C13.2073 11.7925 13.4311 11.8853 13.6645 11.8853C13.8979 11.8853 14.1218 11.7925 14.2868 11.6275C14.4518 11.4625 14.5446 11.2386 14.5446 11.0052C14.5404 10.7746 14.4459 10.5549 14.2813 10.3933C14.1167 10.2317 13.8952 10.1413 13.6645 10.1414V10.1254Z"
      fill="#1A1A1A"
    />
    <path
      d="M21.8091 19.5794C21.4965 19.5638 21.1954 19.4566 20.943 19.2716C20.6907 19.0865 20.4981 18.8316 20.3891 18.5382C20.2802 18.2448 20.2597 17.9259 20.3301 17.621C20.4006 17.3161 20.559 17.0386 20.7856 16.8228C21.0122 16.6069 21.2972 16.4623 21.6051 16.4068C21.9131 16.3512 22.2307 16.3872 22.5184 16.5103C22.8062 16.6334 23.0514 16.8382 23.2239 17.0993C23.3964 17.3604 23.4887 17.6664 23.4891 17.9794C23.4894 18.1964 23.4456 18.4111 23.3602 18.6107C23.2749 18.8102 23.1498 18.9903 22.9926 19.14C22.8355 19.2896 22.6496 19.4058 22.4462 19.4814C22.2427 19.5569 22.0258 19.5903 21.8091 19.5794ZM21.8091 17.0193C21.5757 17.0193 21.3518 17.112 21.1868 17.2771C21.0218 17.4421 20.929 17.6659 20.929 17.8993C20.929 18.1327 21.0218 18.3566 21.1868 18.5216C21.3518 18.6866 21.5757 18.7794 21.8091 18.7794C22.0424 18.7794 22.2663 18.6866 22.4313 18.5216C22.5964 18.3566 22.6891 18.1327 22.6891 17.8993C22.6891 17.6659 22.5964 17.4421 22.4313 17.2771C22.2663 17.112 22.0424 17.0193 21.8091 17.0193V17.0193Z"
      fill="#1A1A1A"
    />
    <path
      d="M20.5914 17.8833C20.4827 17.8784 20.38 17.8328 20.3035 17.7554L14.5754 11.7073C14.5021 11.6326 14.4609 11.5319 14.4609 11.4272C14.4609 11.3225 14.5021 11.2221 14.5754 11.1474C14.6127 11.1087 14.6575 11.0778 14.707 11.0567C14.7565 11.0357 14.8095 11.0249 14.8633 11.0249C14.917 11.0249 14.9704 11.0357 15.0199 11.0567C15.0694 11.0778 15.1142 11.1087 15.1516 11.1474L20.8793 17.1952C20.9526 17.27 20.9938 17.3706 20.9938 17.4753C20.9938 17.58 20.9526 17.6806 20.8793 17.7554C20.8431 17.7959 20.7989 17.8282 20.7492 17.8503C20.6996 17.8724 20.6457 17.8836 20.5914 17.8833V17.8833Z"
      fill="#1A1A1A"
    />
    <path
      d="M8.24066 20.1283C8.17747 20.1427 8.11166 20.1427 8.04847 20.1283C7.95766 20.0749 7.89184 19.9878 7.86488 19.8859C7.83792 19.7841 7.85224 19.6757 7.90472 19.5844L12.2727 11.5844C12.2978 11.538 12.3317 11.497 12.3727 11.4639C12.4137 11.4307 12.4608 11.4061 12.5114 11.3912C12.5619 11.3763 12.6148 11.3717 12.6672 11.3773C12.7196 11.383 12.7706 11.3989 12.8168 11.4242C12.9074 11.4778 12.9739 11.5639 13.0036 11.6648C13.0332 11.7658 13.0237 11.8743 12.9766 11.9684L8.59261 19.9683C8.55225 20.0226 8.4987 20.0656 8.43715 20.0935C8.37559 20.1215 8.30807 20.1336 8.24066 20.1283V20.1283Z"
      fill="#1A1A1A"
    />
    <path
      d="M22.7688 17.4873C22.6748 17.4864 22.5839 17.4523 22.5125 17.3912C22.4392 17.3164 22.3984 17.216 22.3984 17.1113C22.3984 17.0066 22.4392 16.906 22.5125 16.8312L27.3125 10.7834C27.3873 10.7101 27.4879 10.6689 27.5926 10.6689C27.6973 10.6689 27.7979 10.7101 27.8727 10.7834C27.9109 10.8193 27.9413 10.8626 27.9621 10.9107C27.983 10.9589 27.9938 11.0108 27.9938 11.0633C27.9938 11.1158 27.983 11.1677 27.9621 11.2158C27.9413 11.264 27.9109 11.3074 27.8727 11.3434L23.0727 17.4072C23.03 17.4404 22.9812 17.4648 22.9289 17.4785C22.8767 17.4923 22.8222 17.4952 22.7688 17.4873Z"
      fill="#1A1A1A"
    />
    <path
      d="M27.9522 13.7289C27.8581 13.7296 27.7671 13.6957 27.6963 13.6338C27.6255 13.5719 27.5799 13.4861 27.5682 13.3928L27.2799 11.4889L25.2162 11.8408C25.1636 11.8499 25.1097 11.8484 25.0576 11.8363C25.0056 11.8243 24.9564 11.802 24.9131 11.7707C24.8699 11.7394 24.8334 11.6998 24.8057 11.6541C24.778 11.6084 24.7599 11.5577 24.7522 11.5049C24.7376 11.4015 24.762 11.2963 24.8213 11.2103C24.8806 11.1244 24.9703 11.0639 25.0721 11.0408L27.5522 10.6248C27.6508 10.5919 27.7574 10.5919 27.8561 10.6248C27.9013 10.6554 27.939 10.6957 27.9666 10.7428C27.9943 10.7898 28.0114 10.8425 28.0162 10.8969L28.3522 13.2168C28.3659 13.3206 28.3384 13.4258 28.2756 13.5096C28.2128 13.5933 28.1197 13.649 28.0162 13.6648L27.9522 13.7289Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default NavContent;
